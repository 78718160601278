//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PrescriptionService } from '@/services/prescription.service';
export default {
  props: {
    prescriptionData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      processing: false
    };
  },

  methods: {
    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },

    async onEscriptPush() {
      this.alert.show = false;

      try {
        this.processing = true;
        const response = await PrescriptionService.pushEscript(this.prescriptionData.id);
        console.log(response);
        this.$emit('refresh-prescriptions');
        this.processing = false;
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err);
        this.processing = false;
      }
    }

  }
};